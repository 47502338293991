/**
 * SYSTEM TYPES
 * Defines the data structure of the system.
 */
import { StorageReference } from "firebase/storage";
import { Timestamp, FieldValue, DocumentData } from "firebase/firestore";
import { 
    FormStateId, 
    RecordStatus, 
    formStateId, 
    formStateDescription, 
    FormTypeId, 
    formTypeId, 
    formTypeDescription, 
    FormTypeVerboseName, 
    formTypeCodeToVerboseName, 
    formTypeVerboseNameToCode,
    QosValues,
} from "./CLW.Config.types";
import { 
    EventLogEntry, 
    AgentType, 
    EventCategory 
} from "./System.EventLog.types";
import { 
    ConfigFieldTypeId,
    configFieldType,
} from "./CLW.FieldTypes.types";
import { 
    FieldDataTypeId,
    fieldDataType,
    BuiltInFieldTypeId,
    builtInFieldType,
} from "./System.FieldTypes.types";
import { 
    Metric, 
    MetricGroupedBy, 
    MetricRecord, 
    StatisticType, 
    TimePeriod, 
    FrequencyOfCalculation 
} from './System.MetricTypes.types';

export type TimeType = Timestamp | FieldValue;
export { 
    RecordStatus, 
    formStateId, 
    formStateDescription, 
    formTypeId, 
    AgentType,
    formTypeDescription, 
    formTypeCodeToVerboseName, 
    formTypeVerboseNameToCode,
    EventCategory,
    StatisticType, 
    TimePeriod, 
    FrequencyOfCalculation 
};
export type { 
    FormTypeId, 
    FormStateId, 
    EventLogEntry, 
    FormTypeVerboseName,
    QosValues, 
    Metric, 
    MetricGroupedBy, 
    MetricRecord 
};

/**
 * Represents the field types in the system.
 * This is a union of the config field types, the built-in field types, and the field data types.
 * These are used to render the UI.
 */
export type FieldTypeId = ConfigFieldTypeId | BuiltInFieldTypeId | FieldDataTypeId;

/**
 * Combines all field types in the system. 
 * This includes config field types, built-in field types, and field data types.
 */
export const fieldType: Record<FieldTypeId, FieldType> = {
    ...fieldDataType,
    ...builtInFieldType,
    ...configFieldType,
};

// DEPRECATED: Import and export Layout from System.Deprecated.types (to be removed)
import { Layout } from "./System.Deprecated.types";
export type { Layout };

/**
 * Form document, such as a quote or invoice.
 * Is a DB document.
 * Stored in the collection `forms/`.
 */
export type Form = {
    /** Unique identifier for the form 
     * This field is system generated and not present in the document data as a field.
     * Generally, JSON files and document write operations should not include this field.
     */
    docId?: DocId;
    /** Title of the form */
    title?: string;
    /** Form number */
    formNumber?: string;
    /** Organization associated with the form */
    organization: string;
    /** User roles associated with the form */
    userRoles: { 
        [key: string]: UserDBRole 
    };
    /** User group associated with the form (singular) */
    userGroup?: string;
    /** Type (formTypeId) of the form */
    formType: FormType["docId"];
    /** Current version (formVersionId) of the form */
    currentVersionId: FormVersion["docId"];
    /** External IDs from other systems */
    externalIds?: Record<string, string>;
    /** Metadata for the form */
    meta?: Meta;
    /** Subcollection `forms/{formId}/formVersions/` for the versions of this form */
};

/**
 * Represents a version of a Form, which have states such as draft, submitted, approved, and archived.
 * Is a DB document.
 * Stored in the sub-collection `forms/{formId}/formVersions/`.
 */
export type FormVersion = {
    /** The form version id
     * This field is system generated and not present in the document data as a field.
     * Generally, JSON files and document write operations should not include this field.
     */
    docId?: DocId;
    /** The state of the form version */
    state: FormStateId;
    /** Type (formTypeId) of the form. This is a copy of the formType from the parent Form for easier access and querying */
    parentFormType?: FormType["docId"];
    /** Form data: field names and values specific to this form */
    fields: Fields;
    /** References to EntityRecords used in this form */
    entityReferences: {
        [entityId: string]: EntityRecord["docId"];
    };
    /** Associated files */
    associatedFiles?: { 
        /** The associated file id, maps to File type documents in the collection `files/` */
        [fileId: DocId]: { 
            /** Notes about the file association */
            fileNotes?: string;
            /** Tags for the file association */
            fileTags?: string[];
            /** metadata for the file association */
            meta?: Meta;
        }
    };
    /** Metadata for the form data */
    meta?: Meta;
    layoutId?: UILayout["docId"];
};

/**
 * Defines the type of a Form, such as a quote or invoice.
 * Is a DB document.
 * Stored in the collection `formTypes/`.
 */
export type FormType = {
    /** Unique identifier for the form type 
     * This field is system generated and not present in the document data as a field.
     * Generally, JSON files and document write operations should not include this field.
     */
    docId?: DocId;
    /** The possible states for the form type */
    possibleStates: {  
        [key: string]: FormState 
    };
    /** The default format for the form type using the new UILayout structure */
    defaultUILayout: UILayout;
    /** DEPRECATED: The default layout for the form type using the old Layout structure */
    defaultLayoutId: Layout["docId"];
    /** Array of form types that can be created next */
    allowedNextForms?: FormTypeId[];
    /** The name of the starting state for new documents of this type */
    initialState?: FormStateId;
    /** Description of the document type */
    description?: Description;
    /** Metadata for the form type */
    meta?: Meta;
    /** Subcollection `formTypes/{formTypeId}/uiLayouts/` for the UILayouts for this form type */
};

/**
 * Implements the logic for a form state in the workflow around Forms.
 * Is a map or object field.
 */
export type FormState = {
    /** The name of the state 
     * This field is system generated and not present in the document data as a field.
     * Generally, JSON files and document write operations should not include this field.
     */
    docId?: FormStateId;
    /** Description of the document state */
    description?: Description;
    /** Array of state formStateId that this state can transition to */
    allowedNextStates: FormStateId[];
    /** Rules that enable the state */
    entryRules?: { 
        [key: string]: FormRule 
    };
    /** Rules that enable the state */
    exitRules?: { 
        [key: string]: FormRule 
    };
    /** Actions that are performed when entering the state */
    onEntryActions?: { 
        [key: string]: FormAction 
    };
    /** Actions that are performed when exiting the state */
    onExitActions?: { 
        [key: string]: FormAction 
    };
    /** Metadata for the form state */
    meta?: Meta;
};

/**
 * Represents a rule for a form state.
 * Is a map field.
 */
export type FormRule = {
    /** The function name (in code) for the rule */
    functionName: string;
    /** Condition for the rule */
    condition: () => boolean;
    /** Error message for the rule */
    errorMessage?: string;
    /** Description of the rule */
    description?: Description;
    /** Metadata for the rule */
    meta?: Meta;
};

/**
 * Represents an action for a form state.
 * Is a map or object field.
 */
export type FormAction = {
    /** The action data */
    action: () => void;
    /** Description of the action */
    description?: Description;
    /** Metadata for the action */
    meta?: Meta;
};

/**
 * Represents UI definitions to display data in forms or entities.
 * Is a map or a DB document.
 * Builds the layout of the form UI.
 * Stored in subcollection `/formTypes/{formTypeId}/uiLayouts/` for the parent form type.
 */
export interface UILayout {
    /** Unique identifier for the UILayout 
     * This field is system generated and not present in the document data as a field.
     * Generally, JSON files and document write operations should not include this field.
     */
    docId?: DocId;
    /** Structure of the UILayout */
    structure: UIElement;
    /** Description of the UILayout */
    description?: Description;
    /** Metadata for the UILayout */
    meta?: Meta;
}

/** Enum representing the types of UI elements in the layout structure.
 * @enum {string}
 */
export const enum UIElementType {
    /** Grid container for complex, multi-column layouts. */
    Grid = 'grid',
    /** Row container for horizontal arrangement of UI elements. */
    Row = 'row',
    /** Column container for vertical arrangement of UI elements. */
    Column = 'column',
    /** Field element for rendering individual form fields or data display elements. */
    Field = 'field',
    /** Entity reference element for linking and displaying related entity data. */
    EntityReference = 'entityReference'
}

/**
 * Represents a UI element in the layout structure.
 * Builds the layout of the form UI.
 * Can be either a container, a field, or an entity reference.
 */
export type UIElement = UIContainer | UIField | UIEntityReference;

/**
 * Represents a container element in the UI layout.
 * Builds the layout of the form UI.
 * Can contain other containers, fields, or entity references.
 */
export interface UIContainer {
    /** Type of the container for UI rendering */
    uiElementType: UIElementType.Grid | UIElementType.Row | UIElementType.Column;
    /** Child elements of the container */
    children: UIElement[];
    /** Optional properties for container rendering (e.g., width, height, gap) */
    props?: Record<string, any>;
}

/**
 * Represents a field element in the UI layout.
 * Builds the layout of the form UI.
 */
export interface UIField {
    /** Type of the element for UI rendering */
    uiElementType: UIElementType.Field;
    /** Field type of the field */
    fieldTypeId: FieldTypeId;
    /** ID of the field this element represents, maps to a Field value */
    fieldId: string;
    /** Columns for the field (for table rendering) */
    columns?: [{
        dataIndex: string;
        key: string;
        celltype: string;
        title?: string;
        [key: string]: any;
    }];
    /** Status of the field */
    status?: RecordStatus;
    /** Optional properties for field rendering */
    props?: Record<string, any>;
}

/**
 * Represents an entity reference element in the UI layout.
 * Builds the layout of the form UI.
 */
export interface UIEntityReference {
    /** Type of the element for UI rendering */
    uiElementType: UIElementType.EntityReference;
    /** Key of the entity reference in the form's entityReferences */
    entityId: Entity["docId"];
    /** Optional properties for entity reference rendering */
    props?: Record<string, any>;
}

/**
 * Represents an type of record entity in the system, such as customer or project.
 * Is a DB document.
 * Stored in the collection `entities/`.
 */
export type Entity = {
    /** Unique identifier for the entity 
     * This field is system generated and not present in the document data as a field.
     * Generally, JSON files and document write operations should not include this field.
     */
    docId?: DocId;
    /** Name of the entity */
    name: string;
    /** Status of the entity */
    status: RecordStatus;
    /** Default UILayout for the entity */
    defaultUILayout: UILayout;
    /** Description of the entity */
    description?: Description;
    /** User roles associated with the entity */
    userRoles?: { 
        [key: string]: UserDBRole 
    };
    /** User group associated with the entity (singular) */
    userGroup?: string;
    /** Metadata for the entity */
    meta?: Meta;
    // Note: EntityRecords are stored in a subcollection `entities/{entityId}/records/`
    // but are not referenced here as they are handled by the database structure
};

/**
 * Represents a specific record of an Entity, such as a particular customer or project.
 * Is a DB document.
 * Stored in the subcollection `entities/{entityId}/records/`.
 */
export type EntityRecord = {
    /** Unique identifier for the entity record
     * This field is system generated and not present in the document data as a field.
     * Generally, JSON files and document write operations should not include this field.
     */
    docId?: DocId;
    /** Status of the entity record */
    status?: RecordStatus;
    /** Name of the entity record */
    name: string;
    /** Fields storing the data for this entity record */
    fields: Fields;
    /** Metadata for the entity record */
    meta?: Meta;
};

/**
 * Represents a collection of Field with user defined meaning and values.
 * Is a map field.
 */
export type Fields = {
    [field: string]: Field;
};

/**
 * Represents a org-level definition and the stored data value.
 */
export type Field = {
    /** Value of the field */
    value: any;
    /** Type of the field */
    fieldTypeId: FieldTypeId;
    /** Description of the field */
    description?: Description;
    /** Metadata for the field */
    meta?: Meta;
};

/**
 * Represents a type of Field.
 * Defines the data type and the UI rendering.
 */
export type FieldType = {
    /** Unique identifier for the field type */
    fieldTypeId?: FieldTypeId;
    /** The base field data type ID for this field type */
    fieldDataTypeId: FieldDataTypeId;
    /** UI labels for the field type */
    description?: Description;
    /** Status of the field type */
    status?: RecordStatus;
    /** Metadata for the field type */
    meta?: Meta;
    /** Default value for the field */
    defaultValue?: any;
    /** Validation rules for the field */
    validation?: string | ((value: any) => boolean);
}

/**
 * Represents a UI control in the system.
 */
export type UIControl = {
    /** Unique identifier for the UI control */
    uiControlId: string;
    /** antd UI control to use (e.g., 'Input', 'Select', 'DatePicker') */
    uiControl: string;
    /** Properties for the UI control */
    uiControlProps?: {
        [key: string]: any;
    };
    /** Description of this UI option */
    description?: Description;
};

/**
 * Represents a user in the system.
 * Is a DB document.
 * Stored in the collection `users/`.
 */
export type User = {
    /** Unique identifier for the user 
     * This field is system generated and not present in the document data as a field.
     * Generally, JSON files and document write operations should not include this field.
     */
    docId?: DocId;
    /** Name of the user */
    name: string;
    /** Email of the user */
    email: string;
    /** Super user flag */
    super?: boolean;
    /** Metadata for the user */
    meta: Meta;
};

/**
 * Represents the data types that can be used in Table and List components.
 */
export type TableDataType = string | number | boolean | Timestamp;

/**
 * Returns the type of the value as a string.
 * @param value The value to get the type of
 * @returns The type of the value as a string
 */
export function tableTypeOf(value: TableDataType): string {
    if (value instanceof Timestamp) return 'timestamp';
    return typeof value;
}

/**
 * Represents an associated file in the system.
 * Is a DB document.
 * Stored in the collection `files/`.
 */
export type File = {
    /** Unique identifier for the associated document 
     * This field is system generated and not present in the document data as a field.
     * Generally, JSON files and document write operations should not include this field.
     */
    docId?: DocId;
    /** Type of the associated document */
    associatedFileType: string;
    /** Notes about the associated document */
    associatedFileNotes?: string;
    /** Storage reference for the associated document */
    associatedFileStorageReference: StorageReference;
    /** Metadata for the associated file */
    meta?: Meta;
};

/**
 * Represents a user role in the system.
 */
export type UserRole = {
    /** Optional array of permissions associated with the role */
    permissions?: { 
        [key: string]: string 
    };
    /** Description of the user role */
    description?: Description;
    /** Metadata for the user role */
    meta?: Meta;
};

/**
 * Enum representing the user roles for documents and collections in the document database.
 */
export enum UserDBRole {
    /** Owner: read, write and manage access */
    Owner = "owner",
    /** Editor: read, write access */
    Editor = "editor",
    /** Viewer: read access */
    Viewer = "viewer"
}

/**
 * Represents a user readable description of a data object.
 * Is a map field.
 */
export type Description = {
    /** Short label for the action */
    shortLabel?: string;
    /** Short description of the action */
    shortDescription?: string;
    /** Long label for the action */
    longLabel?: string;
    /** Long description of the action */
    longDescription?: string;
};

/**
 * Represents metadata for top level objects.
 * Is a map field.
 */
export type Meta = {
    /** Timestamp of the creation */
    created?: TimeType;
    /** Timestamp of the last modification */
    lastModified?: TimeType;
    /** Purpose of the object */
    purpose?: string;
    /** User ID associated with the object */
    userId?: string;
    /** Type of the event */
    agentType?: AgentType;
    /** Version of the object */
    version?: string;
};

/**
 * Represents a document identifier in the document database.
 * This field is system generated and not present in the document data as a field.
 * Generally, JSON files and document write operations should not include this field.
 */
export type DocId = string;

/**
 * Represents a document data with an identifier.
 * We add the document Id to the data to make it easier to work with.
 * Not to be used in writing to the database—the database should not have a duplicate Id.
 */
export type DocDataWithId<T extends DocumentData = DocumentData> = T & { docId?: DocId };
